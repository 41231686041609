import axios from 'axios';

import config from '@/config/config';

const apiClient = axios.create({
  baseURL: `${config?.photos?.url}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': config.omwServer.apiKey,
  },
});

export async function getPhotoList(token) {
  try {
    const params = new URLSearchParams({
      token,
    });
    return (
      await apiClient.get('', {
        params,
      })
    ).data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function getPhoto(token, id) {
  const params = new URLSearchParams({
    token,
  });
  return (
    await apiClient.get(`/${id}`, {
      params,
    })
  ).data;
}

export async function uploadPhoto(photo, token) {
  try {
    const params = new URLSearchParams({
      token,
    });
    const uploadUrl = (
      await apiClient.get(`upload/get-url`, {
        params,
      })
    ).data;

    await axios.put(uploadUrl, photo, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });

    return (
      await apiClient.get(`upload/process`, {
        params,
      })
    ).data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function removePhoto(token, id) {
  try {
    const params = new URLSearchParams({
      token,
    });
    await apiClient.delete(`/${id}`, {
      params,
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
}
